import classNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Product as IProduct, ProductVariant } from "../../interfaces/data";
import { getLocalizedField, setLocalizedFieldOnObject, trimString } from "../../lib/util";
import {
    deleteProductThunk,
    moveProductThunk,
    moveProductVariantThunk,
    selectIsEnabledProduct,
    selectProductLengthByCategory,
    setEditedProduct,
    updateProductThunk,
} from "../../store/app";
import { Button } from "../button";
import { Checkbox } from "../checkbox";
import { Input } from "../input";
import { TextArea } from "../textarea";

interface Props extends IProduct {
    menuIds: string[];
    categoryId: string;
}

interface FormProps extends Props {
    onChanged: (changed: boolean) => void;
}

interface MaskedPriceLUT {
    [key: string]: string;
}

const ProductForm: FunctionComponent<FormProps> = props => {
    const lc = {
        id: props.id,
        title: props.title,
        description: props.description,
        status: props.status,
        menus: props.menus,
        categories: props.categories,
        variants: props.variants.map(v => ({
            ...Object.assign({}, v),
            translations: (v.translations || []).map(t => Object.assign({}, t)),
        })),
        translations: props.translations?.map(t => Object.assign({}, t)) || [],
    };

    const [localProduct, setLocalProduct] = useState<IProduct>(lc);
    const [maskedPriceLUT, setMaskedPriceLUT] = useState<MaskedPriceLUT>(
        props.variants.reduce<MaskedPriceLUT>((lut, variant) => {
            lut[variant.id] = variant.price?.toFixed(2);

            return lut;
        }, {}),
    );
    const editedProduct = useSelector(s => s.app.productEdit);
    const edit = useSelector(s => s.app.productEdit === props.id);
    const lang = useSelector(s => s.app.lang);
    const transMode = useSelector(s => s.app.transMode);
    const status = useSelector(s => s.app.status);
    const menus = useSelector(s => s.app.menus);
    const productLength = useSelector(selectProductLengthByCategory(props.categoryId));
    const dispatch = useDispatch();

    const setDataFromStore = () => {
        const lc = {
            id: props.id,
            title: props.title,
            description: props.description,
            status: props.status,
            menus: props.menus,
            categories: props.categories,
            variants: props.variants.map(v => ({
                ...Object.assign({}, v),
                translations: (v.translations || []).map(t => Object.assign({}, t)),
            })),
            translations: props.translations?.map(t => Object.assign({}, t)) || [],
        };

        setLocalProduct(lc);
        setMaskedPriceLUT(
            props.variants.reduce<MaskedPriceLUT>((lut, variant) => {
                lut[variant.id] = variant.price?.toFixed(2);

                return lut;
            }, {}),
        );

        props.onChanged(false);
    };

    useEffect(() => {
        if (status !== "succeeded") {
            return;
        }

        setDataFromStore();
    }, [status]);

    const handleTitleChange = (val: string) => {
        props.onChanged(true);
        setLocalProduct(setLocalizedFieldOnObject(localProduct, { title: val }, lang, transMode));
    };

    const handleDescriptionChange = (val: string) => {
        props.onChanged(true);
        setLocalProduct(setLocalizedFieldOnObject(localProduct, { description: val }, lang, transMode));
    };

    const handleUnitChange = (variant: ProductVariant | null, val: string) => {
        if (!variant) {
            return;
        }

        props.onChanged(true);

        setLocalProduct({
            ...localProduct,
            variants: localProduct.variants.map(v => {
                if (v.id === variant.id) {
                    return setLocalizedFieldOnObject(v, { unit: val }, lang, transMode);
                }

                return v;
            }),
        });
    };

    const handlePriceChange = (variant: ProductVariant | null, val: string) => {
        if (!variant) {
            return;
        }

        props.onChanged(true);

        setMaskedPriceLUT({
            ...maskedPriceLUT,
            [variant.id]: val,
        });
    };

    const handleAddVariant = () => {
        props.onChanged(true);

        setLocalProduct({
            ...localProduct,
            variants: [
                ...localProduct.variants,
                { id: "_tmp-" + new Date().getTime(), unit: "", price: 0, status: "published" },
            ],
        });
    };

    const handleVariantOptionClick = (id: string, key?: string) => {
        switch (key) {
            case "moveUp":
                props.onChanged(true);
                dispatch(moveProductVariantThunk({ productId: props.id, variantId: id, direction: "up" }));
                break;

            case "moveDown":
                props.onChanged(true);
                dispatch(moveProductVariantThunk({ productId: props.id, variantId: id, direction: "down" }));
                break;

            case "delete":
                props.onChanged(true);
                setLocalProduct({
                    ...localProduct,
                    variants: localProduct.variants.filter(v => v.id !== id),
                });
                break;
        }
    };

    const handleSaveClick = () => {
        if (!localProduct.title) {
            alert("Titel darf nicht leer sein!");
            return;
        }

        props.onChanged(true);
        localProduct.variants = localProduct.variants.map(v => {
            const maskedPrice = maskedPriceLUT[v.id];
            if (maskedPrice) {
                v.price = parseFloat(maskedPrice);
            }

            return v;
        });

        for (const v of localProduct.variants) {
            if (v.price == undefined || v.price == null) {
                alert("Preis von Variante darf nicht leer sein!");
                return;
            }
        }

        dispatch(updateProductThunk(localProduct));
        dispatch(setEditedProduct(null));
    };

    const handleEditClick = () => {
        if (editedProduct === null) {
            dispatch(setEditedProduct(props.id));
        } else {
            alert("Es wird gerade ein anderes Produkt bearbeitet!");
        }
    };

    const handleAbortClick = () => {
        setDataFromStore();
        dispatch(setEditedProduct(null));
    };

    const handleOptionClick = (key?: string) => {
        switch (key) {
            case "moveUp":
                dispatch(moveProductThunk({ catId: props.categoryId, prodId: props.id, direction: "up" }));
                break;

            case "moveDown":
                dispatch(moveProductThunk({ catId: props.categoryId, prodId: props.id, direction: "down" }));
                break;

            case "delete":
                const confirmed = window.confirm("Produkt löschen?");
                if (!confirmed) return;

                dispatch(deleteProductThunk(localProduct.id));
                break;
        }
    };

    const handleMenuClick = (menuId: string, checked: boolean) => {
        props.onChanged(true);
        if (checked) {
            setLocalProduct({
                ...localProduct,
                menus: [...(localProduct.menus || []), { menu_id: menuId }],
            });
        } else {
            setLocalProduct({
                ...localProduct,
                menus: localProduct.menus.filter(m => m.menu_id !== menuId),
            });
        }
    };

    const getVariantSubmenu = (showMovement: boolean) => {
        const submenu = [] as { key: string; label: string }[];

        if (showMovement) {
            submenu.push({ key: "moveUp", label: "Nach oben" });
            submenu.push({ key: "moveDown", label: "Nach unten" });
        }

        submenu.push({ key: "delete", label: "Löschen" });

        return submenu;
    };

    const getSubmenu = () => {
        const submenu = [] as { key: string; label: string }[];

        if (productLength > 1) {
            submenu.push({ key: "moveUp", label: "Nach oben" });
            submenu.push({ key: "moveDown", label: "Nach unten" });
        }

        submenu.push({ key: "delete", label: "Löschen" });

        return submenu;
    };

    return (
        <div
            className={classNames("flex", {
                "cursor-not-allowed": !edit,
            })}
        >
            <div
                className={classNames({
                    "pointer-events-none": !edit,
                    "pointer-events-all": edit,
                })}
                style={{ width: "240px" }}
            >
                <Input
                    value={getLocalizedField(localProduct, "title", lang, transMode) || ""}
                    onChange={handleTitleChange}
                    placeholder={localProduct.title || "Name..."}
                />
            </div>
            <div
                className={classNames("ml-2", {
                    "pointer-events-none": !edit,
                    "pointer-events-all": edit,
                })}
                style={{ minWidth: "300px" }}
            >
                <TextArea
                    value={getLocalizedField(localProduct, "description", lang, transMode) || ""}
                    onChange={handleDescriptionChange}
                    placeholder={localProduct.description || "Beschreibung..."}
                />
            </div>
            <div
                className={classNames("ml-2", {
                    "pointer-events-none": !edit,
                    "pointer-events-all": edit,
                })}
                style={{ width: "280px" }}
            >
                <div className="grid grid-cols-1 gap-2 w-full">
                    {localProduct.variants.map(variant => (
                        <div key={variant.id} className="flex gap-2">
                            <Input
                                value={getLocalizedField(variant, "unit", lang, transMode)}
                                onChange={v => handleUnitChange(variant, v)}
                                placeholder={variant.unit || "Einheit/Beschreibung"}
                            />
                            <div
                                className={classNames("flex flex-shrink-0", {
                                    "opacity-50 pointer-events-none": transMode,
                                })}
                                style={{ width: "80px" }}
                            >
                                <Input
                                    className="text-right"
                                    value={maskedPriceLUT[variant.id]}
                                    onChange={v => handlePriceChange(variant, v)}
                                    placeholder="Preis"
                                    inputProps={{ type: "number", step: "0.01", disabled: transMode }}
                                />
                            </div>
                            {!transMode && (
                                <div className="flex">
                                    <Button
                                        onClick={key => handleVariantOptionClick(variant.id, key)}
                                        label=""
                                        slim
                                        submenu={getVariantSubmenu(localProduct.variants.length > 1)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="currentColor"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                        </svg>
                                    </Button>
                                </div>
                            )}
                        </div>
                    ))}
                    {!transMode && <Button className="w-full" label="+ Variante" onClick={handleAddVariant} />}
                </div>
            </div>
            <div
                className={classNames("ml-auto", {
                    "opacity-50 pointer-events-none": transMode,
                    "pointer-events-none": !edit,
                    "pointer-events-all": edit,
                })}
            >
                {menus.map(menu => (
                    <div key={menu.id} className="mb-2">
                        <Checkbox
                            key={menu.id}
                            label={trimString(menu.title, 18)}
                            checked={Boolean(localProduct.menus.find(m => m.menu_id === menu.id))}
                            onChange={c => handleMenuClick(menu.id, c)}
                        />
                    </div>
                ))}
            </div>
            <div className="ml-6 pointer-events-all">
                <div className="flex gap-2">
                    {!edit && (
                        <Button onClick={handleEditClick} label="" slim>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="currentColor"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
                            </svg>
                        </Button>
                    )}
                    {edit && (
                        <>
                            <Button onClick={handleSaveClick} label="" slim>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="currentColor"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                                </svg>
                            </Button>
                            <Button onClick={handleAbortClick} label="" slim>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="currentColor"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                                </svg>
                            </Button>
                        </>
                    )}
                    {!transMode && !edit && (
                        <Button
                            onClick={key => handleOptionClick(key)}
                            label=""
                            slim
                            submenu={getSubmenu()}
                            submenuRight
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="currentColor"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export const Product: FunctionComponent<Props> = props => {
    const [changed, setChanged] = useState(false);
    const isEnabled = useSelector(selectIsEnabledProduct(props.id));

    return (
        <div
            className={classNames(
                "p-4 border-2 bg-dark-light rounded-lg hover:opacity-100 transition-colors transition-opacity duration-75",
                {
                    "border-white": changed,
                    "border-dark-light": !changed,
                    "opacity-40": !isEnabled,
                },
            )}
        >
            <ProductForm onChanged={setChanged} {...props} />
        </div>
    );
};
