import { RouteComponentProps, useNavigate } from "@reach/router";
import { FC, useEffect, useState } from "react";
import { API } from "../../api/client";
import { Logo } from "../logo";

export const LoginView: FC<RouteComponentProps> = ({ path }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const checkLogin = async (clientId: string) => {
        // await new Promise(r => setTimeout(r, 5000));

        const success = await API.checkLogin(clientId);
        if (success) {
            navigate("/");
        } else {
            setLoading(false);
            setError("Login fehlgeschlagen!");
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const clientId = params.get("client_id");

        if (clientId) {
            setLoading(true);
            checkLogin(clientId);
        }
    }, []);

    const handleLoginClick = async () => {
        if (loading) return;

        setLoading(true);

        const clientId = await API.login();
        const redirectUri = `${window.location.origin}${window.location.pathname}?client_id=${clientId}`;

        window.location.href = `https://accounts.garger.services/login?client_id=${clientId}&redirect_uri=${redirectUri}`;
    };

    return (
        <div>
            <main className="p-4 flex items-center justify-center">
                <div className="bg-dark-light rounded-lg" style={{ width: "375px" }}>
                    <div className="flex p-4">
                        <div className="p-2 bg-primary rounded-lg inline-block">
                            <Logo width="38" height="38" />
                        </div>
                        <div className="pl-4">
                            <div className="font-bold text-xl">Digitale Speisekarte</div>
                            <div className="text-lg">menu.garger.services</div>
                        </div>
                    </div>
                    {error && <div className="bg-red-600 p-2 text-center text-white font-bold">{error}</div>}
                    <div className="p-4">
                        <div
                            onClick={handleLoginClick}
                            className="bg-primary rounded-lg px-2 py-2 text-center font-bold select-none cursor-pointer"
                        >
                            <span>{loading ? "..." : "Anmelden"}</span>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};
