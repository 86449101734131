import { ChangeEvent, FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { closeModal, saveModal, setMenuValues } from "../../store/modal";
import { Button } from "../button";
import { Input } from "../input";
import { TextArea } from "../textarea";
import { getLocalizedField, setLocalizedFieldOnObject } from "../../lib/util";
import { Checkbox } from "../checkbox";

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

export const MenuModal: FunctionComponent = () => {
    const open = useSelector(s => s.modal.visible === "menu");
    const formValues = useSelector(s => s.modal.menuValues || {});
    const lang = useSelector(s => s.app.lang);
    const transMode = useSelector(s => s.app.transMode);
    const dispatch = useDispatch();

    const handleSave = () => {
        const timeRegex = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/;

        if (formValues.valid_from && !timeRegex.test(formValues.valid_from || "")) {
            alert("Zeitformat muss HH:MM:SS sein! z.B. 17:45:00");
            return;
        }

        if (formValues.valid_to && !timeRegex.test(formValues.valid_to || "")) {
            alert("Zeitformat muss HH:MM:SS sein! z.B. 17:45:00");
            return;
        }

        dispatch(saveModal());
    };

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleTitleChange = (val: string) => {
        dispatch(setMenuValues(setLocalizedFieldOnObject(formValues, { title: val }, lang, transMode)));
    };

    const handleInfoChange = (val: string) => {
        dispatch(setMenuValues(setLocalizedFieldOnObject(formValues, { info: val }, lang, transMode)));
    };

    const handleValidFromChange = (val: string) => {
        dispatch(setMenuValues({
            ...formValues,
            valid_from: val,
        }));
    }

    const handleValidToChange = (val: string) => {
        dispatch(setMenuValues({
            ...formValues,
            valid_to: val,
        }));
    }

    const handleAutoSwitchChange = (checked: boolean) => {
        dispatch(setMenuValues({
            ...formValues,
            auto_switch: checked,
        }));
    }

    const handleEnabledChange = (checked: boolean) => {
        dispatch(setMenuValues({
            ...formValues,
            status: checked ? "published" : "draft",
        }));
    }

    return (
        <Modal isOpen={open} style={modalStyles}>
            <div className="text-xl mb-4 font-bold">Karte erstellen / bearbeiten</div>
            <div className="grid gap-2">
                <Input
                    value={getLocalizedField(formValues, "title", lang, transMode) || ""}
                    placeholder="Titel"
                    onChange={val => handleTitleChange(val)}
                />
                <TextArea
                    value={getLocalizedField(formValues, "info", lang, transMode) || ""}
                    placeholder="Info"
                    onChange={val => handleInfoChange(val)}
                />
                <Input
                    value={formValues.valid_from || ""}
                    placeholder="Automatisch anzeigen ab"
                    onChange={val => handleValidFromChange(val)}
                />
                <Input
                    value={formValues.valid_to || ""}
                    placeholder="Automatisch anzeigen bis"
                    onChange={val => handleValidToChange(val)}
                />
                <Checkbox
                    label="Automatisch anzeigen"
                    checked={formValues.auto_switch}
                    onChange={handleAutoSwitchChange}
                />
                <Checkbox
                    label="Aktiviert"
                    checked={formValues.status === "published"}
                    onChange={handleEnabledChange}
                />
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4">
                <Button label="Abbrechen" onClick={handleClose} />
                <Button label="Speichern" onClick={handleSave} />
            </div>
        </Modal>
    );
};
