import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, saveModal, setCategoryGroupValues, setCategoryValues } from "../../store/modal";
import { Button } from "../button";
import Modal from "react-modal";
import { Input } from "../input";
import { TextArea } from "../textarea";
import { getLocalizedField, setLocalizedFieldOnObject } from "../../lib/util";

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

export const CategoryGroupModal: FunctionComponent = (): JSX.Element => {
    const open = useSelector(s => s.modal.visible === "category-group");
    const formValues = useSelector(s => s.modal.categoryGroupValues || {});
    const lang = useSelector(s => s.app.lang);
    const transMode = useSelector(s => s.app.transMode);
    const dispatch = useDispatch();

    const handleSave = () => {
        dispatch(saveModal());
    };

    const handleClose = () => {
        dispatch(closeModal());
    };

    const handleTitleChange = (val: string) => {
        dispatch(setCategoryGroupValues(setLocalizedFieldOnObject(formValues, { title: val }, lang, transMode)));
    };

    return (
        <Modal isOpen={open} style={modalStyles}>
            <div className="text-xl mb-4 font-bold">Kategorie-Gruppe erstellen</div>
            <div className="grid gap-2">
                <Input
                    value={getLocalizedField(formValues, "title", lang, transMode) || ""}
                    placeholder={formValues.title || "Titel..."}
                    onChange={val => handleTitleChange(val)}
                />
            </div>
            <div className="grid grid-cols-2 gap-2 mt-4">
                <Button label="Abbrechen" onClick={handleClose} />
                <Button label="Speichern" onClick={handleSave} />
            </div>
        </Modal>
    );
};
