import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreState } from ".";
import { API } from "../api/client";
import { Category, CategoryFormData, CategoryGroupFormData, MenuFormData, Product } from "../interfaces/data";
import {
    addMenu,
    createNewCategoryGroupThunk,
    createNewCategoryThunk,
    deleteProduct,
    updateCategory,
    updateCategoryGroup,
    updateMenu,
} from "./app";

type FormData = MenuFormData | CategoryFormData | CategoryGroupFormData | Product | null;

//
// State
//
const initialState = {
    visible: null as string | null,
    menuValues: {} as MenuFormData,
    categoryValues: {} as CategoryFormData,
    categoryGroupValues: {} as CategoryGroupFormData,
    productDeleteValues: {} as Product,
};

//
// Thunk
//
export const saveModal = createAsyncThunk(
    "modal/saveModal",
    async (_arg, thunkAPI): Promise<void> => {
        const type = (thunkAPI.getState() as StoreState).modal.visible;
        if (!type) {
            thunkAPI.dispatch(closeModal());
            return;
        }

        const saveMenu = async () => {
            const values = (thunkAPI.getState() as StoreState).modal.menuValues;

            if (values.id) {
                // Update
                const body = await API.patchMenu({
                    ...values,
                    valid_from: values.valid_from || null,
                    valid_to: values.valid_to || null,
                });

                if (body) {
                    thunkAPI.dispatch(updateMenu(body as any));
                }
            } else {
                // Create
                const body = await API.createMenu({
                    title: values.title,
                    info: values.info,
                    valid_from: values.valid_from || null,
                    valid_to: values.valid_to || null,
                    status: values.status || "published",
                    auto_switch: values.auto_switch || false,
                    categories: [],
                    sort: 0,
                    translations: [],
                });

                if (body) {
                    thunkAPI.dispatch(addMenu(body));
                }
            }
        };

        const saveCategory = async () => {
            const values = (thunkAPI.getState() as StoreState).modal.categoryValues;

            const setParent = (values: CategoryFormData): Partial<Category> => {
                if (values.parentId) {
                    return {
                        ...values,
                        parent_category: {
                            id: values.parentId,
                        },
                    };
                }

                return {
                    ...values,
                    parent_category: null,
                };
            };

            if (values.id) {
                // Update
                const body = await API.patchCategory(setParent(values));

                if (body) {
                    thunkAPI.dispatch(updateCategory(body));
                }
            } else {
                const payload = {
                    title: values.title,
                    description: values.description,
                    parent_category: null as Partial<Category> | null,
                };
                if (values.parentId) {
                    payload.parent_category = {
                        id: values.parentId,
                    };
                }
                thunkAPI.dispatch(createNewCategoryThunk(payload));
            }
        };

        const saveCategoryGroup = async () => {
            const values = (thunkAPI.getState() as StoreState).modal.categoryGroupValues;

            if (values.id) {
                // Update
                const body = await API.patchCategoryGroup(values);

                if (body) {
                    thunkAPI.dispatch(updateCategoryGroup(body));
                }
            } else {
                thunkAPI.dispatch(
                    createNewCategoryGroupThunk({
                        title: values.title,
                    }),
                );
            }
        };

        switch (type) {
            case "menu":
                await saveMenu();
                break;

            case "category":
                await saveCategory();
                break;

            case "category-group":
                await saveCategoryGroup();
                break;
        }

        thunkAPI.dispatch(closeModal());
    },
);

//
// Slice
//
export const modalSlice = createSlice({
    name: "modal",
    initialState: { ...initialState },
    reducers: {
        showModal(state, action: PayloadAction<[string, FormData]>) {
            const newState = {
                ...state,
                visible: action.payload[0],
            };

            switch (action.payload[0]) {
                case "menu":
                    newState.menuValues = (action.payload[1] as MenuFormData) || null;
                    break;

                case "category":
                    if (action.payload[1]) {
                        const payload = action.payload[1] as Category;

                        newState.categoryValues = {
                            ...payload,
                            parentId: payload.parent_category?.id,
                        };
                    }
                    break;

                case "category-group":
                    newState.categoryGroupValues = (action.payload[1] as CategoryGroupFormData) || null;
                    break;

                case "product-delete":
                    newState.productDeleteValues = (action.payload[1] as Product) || null;
                    break;
            }

            return newState;
        },
        closeModal(state) {
            return { ...initialState };
        },
        setMenuValues(state, action: PayloadAction<MenuFormData>) {
            return {
                ...state,
                menuValues: action.payload,
            };
        },
        setCategoryValues(state, action: PayloadAction<CategoryFormData>) {
            return {
                ...state,
                categoryValues: action.payload,
            };
        },
        setCategoryGroupValues(state, action: PayloadAction<CategoryGroupFormData>) {
            return {
                ...state,
                categoryGroupValues: action.payload,
            };
        },
    },
});

export default modalSlice.reducer;

//
// Actions
//
export const { showModal, closeModal, setMenuValues, setCategoryValues, setCategoryGroupValues } = modalSlice.actions;
