import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Category } from "../../interfaces/data";
import { fetchData, saveCategoryPositionsThunk } from "../../store/app";
import { AppBar } from "../appbar";
import { Button } from "../button";

export const SortView: FC<RouteComponentProps> = ({ path }) => {
    const categories = useSelector(s => s.app.categories);
    const status = useSelector(s => s.app.status);
    const dispatch = useDispatch();

    const [localCategories, setLocalCategories] = useState([...categories]);

    useEffect(() => {
        dispatch(fetchData());
    }, []);

    useEffect(() => {
        setLocalCategories([...categories]);
    }, [categories]);

    const handleMoveUp = (cat: Category, index: number) => {
        if (index === 0) {
            alert("Kann Kategorie nicht nach oben verschieben!");
            return;
        }

        const updatedCategories = [...localCategories];

        const old = updatedCategories[index - 1];
        updatedCategories[index - 1] = cat;
        updatedCategories[index] = old;

        setLocalCategories(updatedCategories);
    };

    const handleMoveDown = (cat: Category, index: number) => {
        if (index >= localCategories.length - 1) {
            alert("Kann Kategorie nicht nach unten verschieben!");
            return;
        }

        const updatedCategories = [...localCategories];

        const old = updatedCategories[index + 1];
        updatedCategories[index + 1] = cat;
        updatedCategories[index] = old;

        setLocalCategories(updatedCategories);
    };

    const handleMoveToX = (cat: Category, index: number) => {
        const target = parseInt(prompt("Ziel-Index?") || "-1");

        if (target < 0 || target >= localCategories.length) {
            alert("Kann Kategorie nicht verschieben!");
            return;
        }

        const updatedCategories = [...localCategories];
        const item = updatedCategories[index];

        updatedCategories.splice(target + 1, 0, item);

        setLocalCategories(updatedCategories.filter(c => Boolean(c)));
    };

    const handleSave = () => {
        const updatedSort = localCategories.map((lc, index) => ({ ...lc, sort: index }));

        dispatch(saveCategoryPositionsThunk(updatedSort));
    };

    return (
        <div>
            <AppBar path={path} />
            <main className="p-4 pt-28 grid grid-cols-1 gap-2">
                {localCategories.map((cat, index) => (
                    <div
                        key={cat.id}
                        className={classNames("p-2 border border-dark-lighter bg-dark-lighter rounded-lg flex transition-colors duration-75 hover:border-white", {
                            "ml-4": Boolean(cat.parent_category),
                        })}
                    >
                        <div>
                            <span className="opacity-50">{index}</span>
                            <span className="pl-4">{cat.title}</span>
                        </div>
                        <div className="ml-auto" onClick={() => handleMoveUp(cat, index)}>
                            Nach oben
                        </div>
                        <div className="ml-4" onClick={() => handleMoveDown(cat, index)}>
                            Nach unten
                        </div>
                    </div>
                ))}
                <Button label="Positionen speichern" onClick={handleSave} />
            </main>
        </div>
    );
};
function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}

