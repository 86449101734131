import { useEffect, useState, VFC } from "react";
import cs from "classnames";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

interface Props {
    className?: string;
    label: string;
    onClick: (key?: string) => void;
    children?: JSX.Element;
    slim?: boolean;
    embedded?: boolean;
    submenu?: {
        key: string;
        label: string;
    }[];
    submenuRight?: boolean;
}

export const Button: VFC<Props> = ({ className, label, onClick, children, slim, embedded, submenu, submenuRight }) => {
    const [showSubmenu, setShowSubmenu] = useState(false);

    const handleClick = () => {
        if (submenu) {
            setShowSubmenu(!showSubmenu);
        } else {
            onClick();
        }
    };

    const handleSubmenuItemClick = (key: string) => {
        onClick(key);
    };

    const handleOutsideClick = () => {
        setShowSubmenu(false);
    };

    return (
        <OutsideClickHandler display="flex" onOutsideClick={handleOutsideClick}>
            <div
                className={cs(
                    className,
                    "bg-dark-lighter rounded-lg select-none font-bold cursor-pointer border-dark-lighter text-center inline-flex justify-center relative",
                    {
                        "py-2 px-6": !slim && !embedded,
                        "p-2": slim && !embedded,
                        "border-2": !embedded,
                    },
                )}
                onClick={() => handleClick()}
            >
                {children ? children : <span>{label}</span>}
                <div
                    className={classNames(
                        "absolute bottom-0 whitespace-nowrap z-10 bg-dark-lighter border-2 border-white transform-gpu translate-y-full rounded-lg transition-opacity duration-75 overflow-hidden",
                        {
                            "left-0": !submenuRight,
                            "right-0": submenuRight,
                            "opacity-100 pointer-events-all": showSubmenu,
                            "opacity-0 pointer-events-none": !showSubmenu,
                        },
                    )}
                >
                    {submenu?.map(item => (
                        <div
                            key={item.key}
                            className="text-left px-2 py-1 bg-dark-lighter hover:bg-dark-light"
                            onClick={() => handleSubmenuItemClick(item.key)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        </OutsideClickHandler>
    );
};
