import classNames from "classnames";
import { ChangeEvent, InputHTMLAttributes, VFC } from "react";

interface Props {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => any;
}

export const Checkbox: VFC<Props> = ({ label, checked, onChange }) => {
    const handleClick = () => {
        onChange(!checked);
    }

    return (
        <div className="flex items-center" onClick={handleClick}>
            <div className="bg-dark-lighter rounded-lg" style={{ width: 24, height: 24 }}>
                <svg
                    className={classNames("transition-opacity duration-75", {
                        "opacity-100": checked,
                        "opacity-0": !checked,
                    })}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="white"
                >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                </svg>
            </div>
            <div className="ml-2">{label}</div>
        </div>
    );
};
