import { stripHtml } from "string-strip-html";
import { Category, Menu, Product } from "../interfaces/data";

export const cleanProduct = (p: Product): Product => {
    if (p.description) {
        p.description = stripHtml(p.description).result;
    }

    if (p.translations) {
        for (let i = 0; i < p.translations.length; i++) {
            if (p.translations[i].description) {
                p.translations[i].description = stripHtml(p.translations[i].description).result;
            }
        }
    }

    return p;
};

export const cleanCategory = (c: Category): Category => {
    if (c.description) {
        c.description = stripHtml(c.description).result;
    }

    if (c.translations) {
        for (let i = 0; i < c.translations.length; i++) {
            if (c.translations[i].description) {
                c.translations[i].description = stripHtml(c.translations[i].description).result;
            }
        }
    }

    return c;
};

export const cleanMenu = (m: Menu): Menu => {
    if (m.info) {
        m.info = stripHtml(m.info).result;
    }

    if (m.translations) {
        for (let i = 0; i < m.translations.length; i++) {
            if (m.translations[i].info) {
                m.translations[i].info = stripHtml(m.translations[i].info).result;
            }
        }
    }

    return m;
}
