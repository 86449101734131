import classNames from "classnames";
import { ChangeEvent, InputHTMLAttributes, VFC } from "react";

interface Props {
    className?: string;
    placeholder: string;
    value: string | undefined;
    onChange: (value: string) => any;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    untranslated?: boolean;
}

export const Input: VFC<Props> = ({ className, placeholder, value, onChange, inputProps, untranslated }) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    return (
        <input
            className={classNames("appearance-none bg-dark-lighter outline-none p-2 rounded-lg w-full", {
                "text-gray-500": untranslated
            }, className)}
            type="text"
            placeholder={placeholder}
            value={value || ""}
            onChange={handleChange}
            {...inputProps}
        />
    );
};
