import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectProductsByCategory } from "../store/app";
import { Product } from "./product";

interface Props {
    categoryId: string;
}

export const ProductList: FunctionComponent<Props> = ({ categoryId }) => {
    const products = useSelector(selectProductsByCategory(categoryId));

    return (
        <div className="grid grid-col-1 gap-2">
            {products
                .sort((a, b) => {
                    const va = a.categories.find(c => c.category_id === categoryId)?.sort || 0;
                    const vb = b.categories.find(c => c.category_id === categoryId)?.sort || 0;

                    return va - vb;
                })
                .map(p => (
                    <Product key={p.id} categoryId={categoryId} menuIds={p.menus.map(m => m.menu_id)} {...p} />
                ))}
        </div>
    );
};
