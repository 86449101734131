import {VFC} from "react";

interface Props {
    width: string;
    height: string;
}

export const Logo: VFC<Props> = ({ width, height }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 52.44 30.84">
        <path fill="white" d="M36.62,9c-9.41-2-17.32,2.61-18.81,5.59,0-.05,10.18-7.69,21.76-1.78,6.62,3.38,8.35,9.39,5.42,9.39s-4.31-2.53-14.12-3c-12.47-.53-17.8,5.68-22.23,3.34S7.21,12.13,10.7,9C14.21,5.69,21,2.36,30.59,3.25,44.06,4.49,51.2,17.1,51.2,17.1S46.3.53,26.16,0C4.64-.55-4.24,21.27,1.91,28.59S14.56,23,27.79,22.9c13.61-.1,21.25,8,23.66,7.86C54.4,30.63,51.14,12.09,36.62,9Z" />
    </svg>
)
