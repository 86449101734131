import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Category, CategoryGroup } from "../../interfaces/data";
import { getLocalizedField } from "../../lib/util";
import {
    addCategoryToGroupThunk,
    deleteCategoryFromGroupThunk,
    deleteCategoryGroupThunk,
    fetchData,
    moveCategoryGroupThunk,
    saveCategoryPositionsThunk,
} from "../../store/app";
import { showModal } from "../../store/modal";
import { AppBar } from "../appbar";
import { Button } from "../button";
import { CategoryGroupModal } from "../modals/group";

export const GroupsView: FC<RouteComponentProps> = ({ path }) => {
    const categories = useSelector(s => s.app.categories);
    const groups = useSelector(s => s.app.groups);
    const lang = useSelector(s => s.app.lang);
    const transMode = useSelector(s => s.app.transMode);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchData());
    }, []);

    const handleAddGroup = () => {
        dispatch(showModal(["category-group", null]));
    };

    const handleOptionClick = (group: CategoryGroup, key?: string) => {
        switch (key) {
            case "moveUp":
                dispatch(moveCategoryGroupThunk({ groupId: group.id, direction: "up" }));
                break;

            case "moveDown":
                dispatch(moveCategoryGroupThunk({ groupId: group.id, direction: "down" }));
                break;

            case "edit":
                dispatch(showModal(["category-group", group]));
                break;

            case "delete":
                const confirmed = window.confirm(`Kategorie-Gruppe "${group.title}" löschen?`);
                if (!confirmed) return;

                dispatch(deleteCategoryGroupThunk(group.id));
                break;
        }
    };

    const handleCategoryOptionClick = (cat: Category, key?: string) => {
        if (key === "delete") {
            dispatch(deleteCategoryFromGroupThunk(cat.id));
            return;
        } else if (key?.includes("add-")) {
            const groupId = key.substr(4);
            dispatch(addCategoryToGroupThunk({ catId: cat.id, groupId }));
            return;
        }
    };

    const getSubmenu = () => {
        const submenu = [] as { key: string; label: string }[];

        if (transMode) {
            submenu.push({ key: "edit", label: "Bearbeiten" });
            return submenu;
        }

        if (groups.length > 1) {
            submenu.push({ key: "moveUp", label: "Nach oben" });
            submenu.push({ key: "moveDown", label: "Nach unten" });
        }

        submenu.push({ key: "edit", label: "Bearbeiten" });
        submenu.push({ key: "delete", label: "Löschen" });

        return submenu;
    };

    const getCategorySubmenu = (cat: Category) => {
        const submenu = [] as { key: string; label: string }[];

        if (cat.groups && cat.groups.length > 0) {
            submenu.push({ key: "delete", label: "Aus Gruppe entfernen" });
        } else {
            for (const group of groups) {
                submenu.push({ key: `add-${group.id}`, label: `Zu Gruppe "${group.title}" hinzufügen` });
            }
        }

        return submenu;
    };

    const renderCategories = (categories: Category[]) => {
        return (
            <div className="grid grid-cols-1 gap-2">
                {categories.map(cat => (
                    <div key={cat.id} className={classNames("p-2 bg-dark-lighter rounded-lg flex justify-between items-center border border-dark-lighter transition-colors duration-75 hover:border-white", {
                        "ml-4": Boolean(cat.parent_category)
                    })}>
                        <div>{cat.title}</div>
                        <Button
                            label=""
                            onClick={key => handleCategoryOptionClick(cat, key)}
                            submenu={getCategorySubmenu(cat)}
                            submenuRight
                            embedded
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="currentColor"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                        </Button>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div>
            <AppBar path={path} />
            <main className="p-4 pt-20 grid grid-cols-1 gap-2">
                {groups.map(group => {
                    const localizedTitle = getLocalizedField(group, "title", lang, transMode);
                    return (
                        <div className="mt-8" key={group.id}>
                            <div className="flex">
                                <div
                                    className={classNames("text-3xl font-bold mb-4", {
                                        "text-gray-500": !localizedTitle,
                                    })}
                                >
                                    {localizedTitle || group.title}
                                </div>
                                <div className="ml-auto h-full">
                                    <Button
                                        onClick={key => handleOptionClick(group, key)}
                                        label=""
                                        slim
                                        submenu={getSubmenu()}
                                        submenuRight
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="currentColor"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                            {renderCategories(
                                categories.filter(
                                    c => c.groups && c.groups.find(g => g.category_group_id === group.id),
                                ),
                            )}
                        </div>
                    );
                })}
                <div className="mt-8">
                    <div className="text-3xl font-bold mb-4 text-gray-500">Ungruppierte Kategorien</div>
                    {renderCategories(categories.filter(c => !c.groups || c.groups.length === 0))}
                </div>
                {!transMode && <Button label="+ Kategorie-Gruppe" onClick={handleAddGroup} />}
            </main>
            <CategoryGroupModal />
        </div>
    );
};
