import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState, StoreState } from ".";
import { API } from "../api/client";
import { Category, CategoryGroup, Content, Menu, Product, ProductVariant } from "../interfaces/data";

//
// State
//
const initialState = {
    menus: [] as Menu[],
    categories: [] as Category[],
    groups: [] as CategoryGroup[],
    products: [] as Product[],
    content: null as Content | null,
    selectedMenu: null as string | null,
    status: "initial" as LoadingState,
    error: null as string | null | undefined,
    lang: "default" as "default" | "en-US",
    productEdit: null as string | null,
    transMode: false,
    pageLoading: false,
    initialLoaded: false,
};

//
// Thunks
//
export const fetchData = createAsyncThunk("app/fetchData", async () => {
    const menus = await API.fetchMenus();
    const categories = await API.fetchCategories();
    const groups = await API.fetchCategoryGroups();
    const products = await API.fetchProducts();
    const content = await API.fetchContent();

    return [menus, categories, groups, products, content] as [Menu[], Category[], CategoryGroup[], Product[], Content];
});

export const saveContentThunk = createAsyncThunk(
    "app/saveContent",
    async (data: Partial<Content>, thunkAPI): Promise<Content> => {
        const content = await API.upsertContent(data);
        return content;
    },
);

export const createNewCategoryGroupThunk = createAsyncThunk(
    "app/createNewCategoryGroup",
    async (data: Partial<CategoryGroup>, thunkAPI): Promise<CategoryGroup> => {
        const groups = (thunkAPI.getState() as StoreState).app.groups;
        const lastSort = groups[groups.length - 1]?.sort || -1;

        const group = await API.createCategoryGroup({
            title: "",
            status: "published",
            sort: lastSort + 1,
            translations: [],
            ...data,
        });

        if (!group) {
            throw new Error("Konnte Kategorie-Gruppe nicht erstellen!");
        }

        return group;
    },
);

export const createNewCategoryThunk = createAsyncThunk(
    "app/createNewCategory",
    async (data: Partial<Category>, thunkAPI): Promise<Category> => {
        const categories = (thunkAPI.getState() as StoreState).app.categories;
        const lastSort = categories[categories.length - 1]?.sort || -1;

        const category = await API.createCategory({
            title: "",
            description: "",
            status: "published",
            sort: lastSort + 1,
            parent_category: null,
            groups: [],
            translations: [],
            ...data,
        });

        if (!category) {
            throw new Error("Konnte Kategorie nicht erstellen!");
        }

        return category;
    },
);

export const createNewProduct = createAsyncThunk(
    "app/createNewProduct",
    async (catId: string, thunkAPI): Promise<Product | null> => {
        const products = (thunkAPI.getState() as StoreState).app.products.filter(p =>
            p.categories.find(c => c.category_id === catId),
        );

        let lastSort = -1;
        for (const prod of products) {
            const catRel = prod.categories.find(c => c.category_id === catId);
            const s = catRel?.sort || 0;

            if (s > lastSort) {
                lastSort = s;
            }
        }

        const product = await API.createProduct({
            title: "NEU",
            status: "published",
            categories: [{ category_id: catId, sort: lastSort + 1 }],
            menus: [],
            variants: [],
        });

        if (!product) {
            throw new Error("Konnte Produkt nicht erstellen!");
        }

        return product;
    },
);

export const moveProductVariantThunk = createAsyncThunk(
    "app/moveProductVariant",
    async (
        { productId, variantId, direction }: { productId: string; variantId: string; direction: "up" | "down" },
        thunkAPI,
    ) => {
        const product = (thunkAPI.getState() as StoreState).app.products.find(p => p.id === productId);
        if (!product) {
            throw new Error("");
        }

        const variants = [...product.variants];

        const sourceIndex = variants.findIndex(v => v.id === variantId);

        if (direction === "up" && sourceIndex === 0) {
            console.warn("Cannot move first item up");
            throw new Error("");
        }

        if (direction === "down" && sourceIndex >= variants.length - 1) {
            console.warn("Cannot move last item down");
            throw new Error("");
        }

        const targetIndex = direction === "up" ? sourceIndex - 1 : sourceIndex + 1;

        const source = variants[sourceIndex];
        const target = variants[targetIndex];

        variants[sourceIndex] = target;
        variants[targetIndex] = source;

        const sortedVariants: ProductVariant[] = [];
        let i = 0;
        for (const v of variants) {
            sortedVariants.push({
                ...v,
                sort: i,
            });

            i++;
        }

        const updatedProduct = await API.updateProductVariantSort(productId, sortedVariants);
        if (!updateProduct) {
            throw new Error("Konnte Produkt nicht aktualisieren!");
        }

        return updatedProduct as Product;
    },
);

export const saveCategoryPositionsThunk = createAsyncThunk(
    "app/saveCategoryPositions",
    async (categories: Category[], thunkAPI) => {
        for (let i = 0; i < categories.length; i++) {
            const cat = categories[i];
            const parentId = cat.parent_category?.id;

            if (!parentId) {
                continue;
            }

            const parent = categories.find(c => c.id === parentId);

            // Go back up in the category array
            for (let j = i; j >= 0; j--) {
                const prev = categories[j];

                // If the parent is above the child, everything is OK
                if (prev.id === parentId) {
                    break;
                }

                // Continue if previous item is also a child of the parent
                if (prev.parent_category?.id === parentId) {
                    continue;
                }

                throw new Error(`"${cat.title}" muss direkt unterhalb von "${parent?.title}" sein!`);
            }
        }

        for (const cat of categories) {
            await API.patchCategory({
                id: cat.id,
                sort: cat.sort,
            });
        }
    },
);

export const moveMenuThunk = createAsyncThunk(
    "app/moveMenu",
    async ({ menuId, direction }: { menuId: string; direction: "up" | "down" }, thunkAPI) => {
        const menus = [...(thunkAPI.getState() as StoreState).app.menus];
        const sourceIndex = menus.findIndex(m => m.id === menuId);

        if (direction === "up" && sourceIndex === 0) {
            throw new Error("Ist bereits ganz oben!");
        }

        if (direction === "down" && sourceIndex >= menus.length - 1) {
            throw new Error("Ist bereits ganz unten!");
        }

        const targetIndex = direction === "up" ? sourceIndex - 1 : sourceIndex + 1;

        const source = menus[sourceIndex];
        const target = menus[targetIndex];

        menus[sourceIndex] = target;
        menus[targetIndex] = source;

        const sortedMenus: Menu[] = [];
        let i = 0;
        for (const menu of menus) {
            sortedMenus.push({
                ...menu,
                sort: i,
            });

            i++;
        }

        const status = await API.updateMenuSort(source.id, target.id);
        if (status !== 200) {
            throw new Error("Konnte Karte nicht verschieben");
        }

        return sortedMenus;
    },
);

export const moveCategoryThunk = createAsyncThunk(
    "app/moveCategory",
    async ({ catId, direction }: { catId: string; direction: "up" | "down" }, thunkAPI) => {
        const categories = [...(thunkAPI.getState() as StoreState).app.categories];
        const sourceIndex = categories.findIndex(c => c.id === catId);

        if (direction === "up" && sourceIndex === 0) {
            throw new Error("Ist bereits ganz oben!");
        }

        if (direction === "down" && sourceIndex >= categories.length - 1) {
            throw new Error("Ist bereits ganz unten!");
        }

        const targetIndex = direction === "up" ? sourceIndex - 1 : sourceIndex + 1;

        const source = categories[sourceIndex];
        const target = categories[targetIndex];

        categories[sourceIndex] = target;
        categories[targetIndex] = source;

        const sortedCategories: Category[] = [];
        let i = 0;
        for (const cat of categories) {
            sortedCategories.push({
                ...cat,
                sort: i,
            });

            i++;
        }

        const status = await API.updateCategorySort(source.id, target.id);
        if (status !== 200) {
            throw new Error("Konnte Kategorie nicht verschieben");
        }

        return sortedCategories;
    },
);

export const moveCategoryGroupThunk = createAsyncThunk(
    "app/moveCategoryGroup",
    async ({ groupId, direction }: { groupId: string; direction: "up" | "down" }, thunkAPI) => {
        const groups = [...(thunkAPI.getState() as StoreState).app.groups];
        const sourceIndex = groups.findIndex(c => c.id === groupId);

        if (direction === "up" && sourceIndex === 0) {
            throw new Error("Ist bereits ganz oben!");
        }

        if (direction === "down" && sourceIndex >= groups.length - 1) {
            throw new Error("Ist bereits ganz unten!");
        }

        const targetIndex = direction === "up" ? sourceIndex - 1 : sourceIndex + 1;

        const source = groups[sourceIndex];
        const target = groups[targetIndex];

        groups[sourceIndex] = target;
        groups[targetIndex] = source;

        const sortedGroups: CategoryGroup[] = [];
        let i = 0;
        for (const group of groups) {
            sortedGroups.push({
                ...group,
                sort: i,
            });

            i++;
        }

        const status = await API.updateCategoryGroupSort(source.id, target.id);
        if (status !== 200) {
            throw new Error("Konnte Kategorie-Gruppe nicht verschieben");
        }

        return sortedGroups;
    },
);

export const moveProductThunk = createAsyncThunk(
    "app/moveProduct",
    async ({ prodId, catId, direction }: { prodId: string; catId: string; direction: "up" | "down" }, thunkAPI) => {
        const products = [...(thunkAPI.getState() as StoreState).app.products]
            .filter(p => p.categories.find(c => c.category_id === catId))
            .sort((a, b) => {
                const va = a.categories.find(c => c.category_id === catId)?.sort || 0;
                const vb = b.categories.find(c => c.category_id === catId)?.sort || 0;

                return va - vb;
            });
        const sourceIndex = products.findIndex(p => p.id === prodId);

        if (direction === "up" && sourceIndex === 0) {
            throw new Error("Ist bereits ganz oben!");
        }

        if (direction === "down" && sourceIndex >= products.length - 1) {
            throw new Error("Ist bereits ganz unten!");
        }

        const targetIndex = direction === "up" ? sourceIndex - 1 : sourceIndex + 1;

        const source = products[sourceIndex];
        const target = products[targetIndex];

        products[sourceIndex] = target;
        products[targetIndex] = source;

        const sortedProducts: Product[] = [];
        let i = 0;
        for (const prod of products) {
            sortedProducts.push({
                ...prod,
                categories: prod.categories.map(cat => {
                    if (cat.category_id === catId) {
                        return {
                            ...cat,
                            sort: i,
                        };
                    }

                    return cat;
                }),
            });

            i++;
        }

        await API.updateProductSort(sortedProducts);

        return sortedProducts;
    },
);

export const updateProductThunk = createAsyncThunk("app/updateProduct", async (product: Product) => {
    const transformedProduct: Product = {
        ...product,
        variants: product.variants.map(v => {
            if (v.id.includes("_tmp")) {
                delete (v as any).id;
            }
            return v;
        }),
    };

    const updatedProduct = await API.patchProduct(transformedProduct);
    if (!product) {
        throw new Error("Konnte Produkt nicht aktualisieren!");
    }

    return updatedProduct;
});

export const deleteProductThunk = createAsyncThunk("app/deleteProduct", async (id: string) => {
    const status = await API.deleteProduct(id);
    if (status !== 200) {
        throw new Error("Konnte Produkt nicht löschen!");
    }

    return id;
});

export const deleteCategoryThunk = createAsyncThunk("app/deleteCategory", async (id: string, thunkAPI) => {
    const category = (thunkAPI.getState() as StoreState).app.categories.find(c => c.id === id);
    if (!category) {
        throw new Error("Kategorie existiert nicht!");
    }

    const categoryProducts = (thunkAPI.getState() as StoreState).app.products.filter(p =>
        p.categories.find(c => c.category_id === category.id),
    );

    if (categoryProducts.length > 0) {
        throw new Error("Kategorie hat noch Produkte!");
    }

    const status = await API.deleteCategory(id);
    if (status !== 200) {
        throw new Error("Konnte Kategorie nicht löschen!");
    }

    return id;
});

export const deleteCategoryFromGroupThunk = createAsyncThunk(
    "app/deleteCategoryFromGroup",
    async (catId: string, thunkAPI) => {
        const category = (thunkAPI.getState() as StoreState).app.categories.find(c => c.id === catId);
        if (!category) {
            throw new Error("Kategorie existiert nicht!");
        }

        const updatedCategory = await API.patchCategory({
            id: category.id,
            groups: [],
        });

        if (!updatedCategory) {
            throw new Error("Konnte Kategorie nicht aus Gruppe entfernen!");
        }

        return updatedCategory;
    },
);

export const addCategoryToGroupThunk = createAsyncThunk(
    "app/addCategoryToGroup",
    async ({ catId, groupId }: { catId: string; groupId: string }, thunkAPI) => {
        const category = (thunkAPI.getState() as StoreState).app.categories.find(c => c.id === catId);
        if (!category) {
            throw new Error("Kategorie existiert nicht!");
        }

        const updatedCategory = await API.patchCategory({
            id: category.id,
            groups: [
                {
                    category_group_id: groupId,
                },
            ],
        });

        if (!updatedCategory) {
            throw new Error("Konnte Kategorie nicht zu Gruppe hinzufügen!");
        }

        return updatedCategory;
    },
);

export const deleteCategoryGroupThunk = createAsyncThunk("app/deleteCategoryGroup", async (id: string, thunkAPI) => {
    const group = (thunkAPI.getState() as StoreState).app.groups.find(g => g.id === id);
    if (!group) {
        throw new Error("Kategorie-Gruppe existiert nicht!");
    }

    const groupCategories = (thunkAPI.getState() as StoreState).app.categories.filter(c =>
        c.groups.find(g => g.category_group_id === c.id),
    );

    if (groupCategories.length > 0) {
        throw new Error("Kategorie-Gruppe hat noch Kategorien!");
    }

    const status = await API.deleteCategoryGroup(id);
    if (status !== 200) {
        throw new Error("Konnte Kategorie-Gruppe nicht löschen!");
    }

    return id;
});

//
// Slice
//
export const appSlice = createSlice({
    name: "app",
    initialState: { ...initialState },
    reducers: {
        // Lang
        setLang(state, action: PayloadAction<"default" | "en-US">) {
            return {
                ...state,
                lang: action.payload,
                transMode: action.payload !== "default",
            };
        },
        // Menu
        setActiveMenu(state, action: PayloadAction<string>) {
            return {
                ...state,
                selectedMenu: action.payload,
            };
        },
        addMenu(state, action: PayloadAction<Menu>) {
            return {
                ...state,
                menus: [...state.menus, action.payload],
                selectedMenu: action.payload.id,
            };
        },
        updateMenu(state, action: PayloadAction<Menu>) {
            return {
                ...state,
                menus: state.menus.map(m => {
                    if (m.id === action.payload.id) {
                        return action.payload;
                    }

                    return m;
                }),
            };
        },
        deleteMenu(state, action: PayloadAction<string>) {
            return {
                ...state,
                menus: state.menus.filter(m => m.id !== action.payload),
            };
        },
        // Category
        addCategory(state, action: PayloadAction<Category>) {
            return {
                ...state,
                categories: [...state.categories, action.payload],
            };
        },
        updateCategory(state, action: PayloadAction<Category>) {
            return {
                ...state,
                categories: state.categories.map(c => {
                    if (c.id === action.payload.id) {
                        return action.payload;
                    }

                    return c;
                }),
            };
        },
        deleteCategory(state, action: PayloadAction<string>) {
            return {
                ...state,
                categories: state.categories.filter(c => c.id !== action.payload),
            };
        },
        // Category Group
        addCategoryGroup(state, action: PayloadAction<CategoryGroup>) {
            return {
                ...state,
                groups: [...state.groups, action.payload],
            };
        },
        updateCategoryGroup(state, action: PayloadAction<CategoryGroup>) {
            return {
                ...state,
                groups: state.groups.map(g => {
                    if (g.id === action.payload.id) {
                        return action.payload;
                    }

                    return g;
                }),
            };
        },
        deleteCategoryGroup(state, action: PayloadAction<string>) {
            return {
                ...state,
                products: state.products.filter(g => g.id !== action.payload),
            };
        },
        // Product
        addProduct(state, action: PayloadAction<Product>) {
            return {
                ...state,
                products: [...state.products, action.payload],
            };
        },
        updateProduct(state, action: PayloadAction<Product>) {
            return {
                ...state,
                products: state.products.map(p => {
                    if (p.id === action.payload.id) {
                        return action.payload;
                    }

                    return p;
                }),
            };
        },
        deleteProduct(state, action: PayloadAction<string>) {
            return {
                ...state,
                products: state.products.filter(p => p.id !== action.payload),
            };
        },
        setEditedProduct(state, action: PayloadAction<string | null>) {
            return {
                ...state,
                productEdit: action.payload,
            };
        },
        // Page
        setPageLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                pageLoading: action.payload,
            };
        },
    },
    extraReducers: builder => {
        // fetchData
        builder.addCase(fetchData.pending, state => {
            state.status = "loading";
        });

        builder.addCase(
            fetchData.fulfilled,
            (state, action: PayloadAction<[Menu[], Category[], CategoryGroup[], Product[], Content]>) => {
                state.status = "succeeded";
                state.initialLoaded = true;
                state.menus = action.payload[0].sort((a, b) => a.sort - b.sort);
                state.selectedMenu = action.payload[0][0]?.id;
                state.categories = action.payload[1].sort((a, b) => a.sort - b.sort);
                state.groups = action.payload[2].sort((a, b) => a.sort - b.sort);
                state.products = action.payload[3];
                state.content = action.payload[4];
            },
        );

        builder.addCase(fetchData.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        });

        // saveContent
        builder.addCase(saveContentThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(saveContentThunk.fulfilled, (state, action: PayloadAction<Content>) => {
            state.status = "succeeded";
            state.content = action.payload;
        });

        builder.addCase(saveContentThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // createNewProduct
        builder.addCase(createNewProduct.pending, state => {
            state.status = "loading";
        });

        builder.addCase(createNewProduct.fulfilled, (state, action: PayloadAction<Product | null>) => {
            state.status = "succeeded";
            if (action.payload) {
                state.products.push(action.payload);
            }
        });

        builder.addCase(createNewProduct.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // updateProduct
        builder.addCase(updateProductThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(updateProductThunk.fulfilled, (state, action: PayloadAction<Product | null>) => {
            state.status = "succeeded";
            state.products = state.products.map(p => {
                if (action.payload && p.id === action.payload.id) {
                    return action.payload;
                }
                return p;
            });
        });

        builder.addCase(updateProductThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // createNewCategory
        builder.addCase(createNewCategoryThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(createNewCategoryThunk.fulfilled, (state, action: PayloadAction<Category>) => {
            state.status = "succeeded";
            if (action.payload) {
                state.categories.push(action.payload);
            }
        });

        builder.addCase(createNewCategoryThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // createNewCategoryGroup
        builder.addCase(createNewCategoryGroupThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(createNewCategoryGroupThunk.fulfilled, (state, action: PayloadAction<CategoryGroup>) => {
            state.status = "succeeded";
            if (action.payload) {
                state.groups.push(action.payload);
            }
        });

        builder.addCase(createNewCategoryGroupThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // moveMenu
        builder.addCase(moveMenuThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(moveMenuThunk.fulfilled, (state, action: PayloadAction<Menu[]>) => {
            state.status = "succeeded";
            state.menus = action.payload;
        });

        builder.addCase(moveMenuThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // moveCategory
        builder.addCase(moveCategoryThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(moveCategoryThunk.fulfilled, (state, action: PayloadAction<Category[]>) => {
            state.status = "succeeded";
            state.categories = action.payload;
        });

        builder.addCase(moveCategoryThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // moveCategoryGroup
        builder.addCase(moveCategoryGroupThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(moveCategoryGroupThunk.fulfilled, (state, action: PayloadAction<CategoryGroup[]>) => {
            state.status = "succeeded";
            state.groups = action.payload;
        });

        builder.addCase(moveCategoryGroupThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // saveCategoryPositions
        builder.addCase(saveCategoryPositionsThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(saveCategoryPositionsThunk.fulfilled, state => {
            state.status = "succeeded";
        });

        builder.addCase(saveCategoryPositionsThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // moveProduct
        builder.addCase(moveProductThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(moveProductThunk.fulfilled, (state, action: PayloadAction<Product[]>) => {
            state.status = "succeeded";
            state.products = state.products.map(p => {
                const t = action.payload.find(a => a.id === p.id);
                if (t) {
                    return t;
                }

                return p;
            });
        });

        builder.addCase(moveProductThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // moveProductVariant
        builder.addCase(moveProductVariantThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(moveProductVariantThunk.fulfilled, (state, action: PayloadAction<Product>) => {
            state.status = "succeeded";
            state.products = state.products.map(p => {
                if (p.id === action.payload.id) {
                    return action.payload;
                }

                return p;
            });
        });

        builder.addCase(moveProductVariantThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // deleteProduct
        builder.addCase(deleteProductThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(deleteProductThunk.fulfilled, (state, action: PayloadAction<string>) => {
            state.status = "succeeded";
            state.products = state.products.filter(p => p.id !== action.payload);
        });

        builder.addCase(deleteProductThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // deleteCategory
        builder.addCase(deleteCategoryThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(deleteCategoryThunk.fulfilled, (state, action: PayloadAction<string>) => {
            state.status = "succeeded";
            state.categories = state.categories.filter(c => c.id !== action.payload);
        });

        builder.addCase(deleteCategoryThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // deleteCategoryFromGroup
        builder.addCase(deleteCategoryFromGroupThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(deleteCategoryFromGroupThunk.fulfilled, (state, action: PayloadAction<Category>) => {
            state.status = "succeeded";
            state.categories = state.categories.map(c => {
                if (c.id === action.payload.id) {
                    return action.payload;
                }

                return c;
            });
        });

        builder.addCase(deleteCategoryFromGroupThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // addCategoryToGroupThunk
        builder.addCase(addCategoryToGroupThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(addCategoryToGroupThunk.fulfilled, (state, action: PayloadAction<Category>) => {
            state.status = "succeeded";
            state.categories = state.categories.map(c => {
                if (c.id === action.payload.id) {
                    return action.payload;
                }

                return c;
            });
        });

        builder.addCase(addCategoryToGroupThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });

        // deleteCategoryGroup
        builder.addCase(deleteCategoryGroupThunk.pending, state => {
            state.status = "loading";
        });

        builder.addCase(deleteCategoryGroupThunk.fulfilled, (state, action: PayloadAction<string>) => {
            state.status = "succeeded";
            state.groups = state.groups.filter(c => c.id !== action.payload);
        });

        builder.addCase(deleteCategoryGroupThunk.rejected, (state, action) => {
            state.status = "succeeded";
            alert(action.error.message);
        });
    },
});

export default appSlice.reducer;

//
// Actions
//
export const {
    setLang,
    setActiveMenu,
    addMenu,
    updateMenu,
    deleteMenu,
    addCategory,
    updateCategory,
    deleteCategory,
    addCategoryGroup,
    updateCategoryGroup,
    deleteCategoryGroup,
    addProduct,
    updateProduct,
    deleteProduct,
    setEditedProduct,
    setPageLoading,
} = appSlice.actions;

//
// Selectors
//
export const selectActiveMenu = (state: StoreState) => {
    return state.app.menus.find(m => m.id === state.app.selectedMenu);
};

export const selectMenusByIds = (ids: string[]) => (state: StoreState) => {
    return state.app.menus.filter(m => ids.includes(m.id));
};

export const selectCategoriesForMenu = (state: StoreState) => {
    const categories = state.app.categories;
    const products = state.app.products;

    const activeMenu = selectActiveMenu(state);
    if (!activeMenu) {
        return [];
    }

    const filteredCategories: Category[] = [];
    const existingCategoryLut: { [key: string]: true } = {};
    // Iterate over each product
    for (const product of products) {
        // Do not proceed if product is not in current menu
        if (!product.menus.find(m => m.menu_id === activeMenu.id)) {
            continue;
        }

        // Iterate over each category for this product
        for (const catRel of product.categories) {
            // Only continue if the category has not been processed yet
            if (!existingCategoryLut[catRel.category_id]) {
                existingCategoryLut[catRel.category_id] = true;

                const cat = categories.find(c => c.id === catRel.category_id);
                if (cat) {
                    filteredCategories.push(cat);
                }
            }
        }
    }

    return filteredCategories;
};

export const selectUnusedCategories = (state: StoreState) => {
    const categories = state.app.categories;
    const usedCategories = selectCategoriesForMenu(state);

    const unusedCategories: Category[] = [];
    for (const cat of categories) {
        if (!usedCategories.find(u => u.id === cat.id)) {
            unusedCategories.push(cat);
        }
    }

    return unusedCategories;
};

export const selectProductsByCategory = (categoryId: string) => (state: StoreState) => {
    const filteredProducts: Product[] = [];

    for (const product of state.app.products) {
        if (product.categories.find(c => c.category_id === categoryId)) {
            filteredProducts.push(product);
        }
    }

    return [...filteredProducts].sort((a, b) => {
        const catRelA = a.categories.find(c => c.category_id === categoryId);
        const catRelB = b.categories.find(c => c.category_id === categoryId);

        if (!catRelA?.sort || !catRelB?.sort) {
            return 0;
        }

        return catRelA.sort - catRelB.sort;
    });
};

export const selectProductLengthByCategory = (categoryId: string) => (state: StoreState) => {
    let i = 0;

    for (const product of state.app.products) {
        if (product.categories.find(c => c.category_id === categoryId)) {
            i++;
        }
    }

    return i;
};

export const selectIsEnabledProduct = (id: string) => (state: StoreState) => {
    const product = state.app.products.find(p => p.id === id);
    if (!product) {
        return false;
    }

    return product.menus && product.menus.length > 0;
};
