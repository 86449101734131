import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import appReducer from "./app";
import modalReducer from "./modal";

const store = configureStore({
    reducer: {
        app: appReducer,
        modal: modalReducer,
    },
});

export type StoreState = ReturnType<typeof store.getState>;

export type AppThunk<R = void> = ThunkAction<R, { app: StoreState }, unknown, Action<string>>;

export type LoadingState = "initial" | "loading" | "succeeded" | "failed";

export default store;

declare module "react-redux" {
    export interface DefaultRootState {
        app: ReturnType<typeof appReducer>;
        modal: ReturnType<typeof modalReducer>;
    }
}
