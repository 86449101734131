import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Category } from "../../interfaces/data";
import { getLocalizedField } from "../../lib/util";
import { createNewProduct, deleteCategoryThunk, fetchData, moveCategoryThunk } from "../../store/app";
import { showModal } from "../../store/modal";
import { AppBar } from "../appbar";
import { Button } from "../button";
import { CategoryModal } from "../modals/category";
import { MenuModal } from "../modals/menu";
import { ProductList } from "../productlist";

export const AdminView: FunctionComponent<RouteComponentProps> = ({ path }) => {
    const menus = useSelector(s => s.app.menus);
    const status = useSelector(s => s.app.status);
    const categories = useSelector(s => s.app.categories);
    const lang = useSelector(s => s.app.lang);
    const transMode = useSelector(s => s.app.transMode);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchData());
    }, []);

    const handleAddCategory = () => {
        dispatch(showModal(["category", null]));
    };

    const handleAddNewProduct = (catId: string) => {
        dispatch(createNewProduct(catId));
    };

    const getSubmenu = () => {
        const submenu = [] as { key: string; label: string }[];

        if (transMode) {
            submenu.push({ key: "edit", label: "Bearbeiten" });
            return submenu;
        }

        // if (categories.length > 1) {
        //     submenu.push({ key: "moveUp", label: "Nach oben" });
        //     submenu.push({ key: "moveDown", label: "Nach unten" });
        // }

        submenu.push({ key: "edit", label: "Bearbeiten" });
        submenu.push({ key: "delete", label: "Löschen" });

        return submenu;
    };

    const handleOptionClick = (cat: Category, key?: string) => {
        switch (key) {
            //             case "moveUp":
            //                 dispatch(moveCategoryThunk({ catId: cat.id, direction: "up" }));
            //                 break;
            //
            //             case "moveDown":
            //                 dispatch(moveCategoryThunk({ catId: cat.id, direction: "down" }));
            //                 break;

            case "edit":
                dispatch(showModal(["category", cat]));
                break;

            case "delete":
                const confirmed = window.confirm("Variante löschen?");
                if (!confirmed) return;

                dispatch(deleteCategoryThunk(cat.id));
                break;
        }
    };

    const renderCategories = () => (
        <>
            {categories.map(cat => {
                const localizedTitle = getLocalizedField(cat, "title", lang, transMode);
                return (
                    <div
                        className={classNames("mt-8 relative", {
                            "pl-12": Boolean(cat.parent_category),
                        })}
                        key={cat.id}
                    >
                        <svg
                            className={classNames("absolute left-0 transform-gpu mirror opacity-0", {
                                "opacity-50": Boolean(cat.parent_category),
                            })}
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="currentColor"
                            style={{ top: "7px" }}
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z" />
                        </svg>
                        <div className="flex">
                            <div
                                className={classNames("text-3xl font-bold mb-4", {
                                    "text-gray-500": !localizedTitle,
                                })}
                            >
                                {localizedTitle || cat.title}
                            </div>
                            <div className="ml-auto h-full">
                                <Button
                                    onClick={key => handleOptionClick(cat, key)}
                                    label=""
                                    slim
                                    submenu={getSubmenu()}
                                    submenuRight
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="currentColor"
                                    >
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                    </svg>
                                </Button>
                            </div>
                        </div>
                        <ProductList categoryId={cat.id} />
                        {!transMode && (
                            <Button
                                className="mt-4 inline-block"
                                label="+ Neues Produkt"
                                onClick={() => handleAddNewProduct(cat.id)}
                            />
                        )}
                    </div>
                );
            })}
            {!transMode && <Button className="mt-4 inline-block" label="+ Kategorie" onClick={handleAddCategory} />}
        </>
    );

    const renderNoMenuNotice = () => <div>Noch keine Karten angelegt!</div>;

    return (
        <div>
            <AppBar path={path} />
            <main className="p-4 pt-20">{menus.length > 0 ? renderCategories() : renderNoMenuNotice()}</main>
            <MenuModal />
            <CategoryModal />
        </div>
    );
};
