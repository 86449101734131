import React, { FunctionComponent } from "react";
import { Router } from "@reach/router";
import { AdminView } from "./views/admin";
import { MenusView } from "./views/menus";
import { SortView } from "./views/sort";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { AppBar } from "./appbar";
import Modal from "react-modal";
import { GroupsView } from "./views/groups";
import { LoginView } from "./views/login";

Modal.setAppElement("#root");

export const App: FunctionComponent = () => {
    const status = useSelector(s => s.app.status);
    const pageLoading = useSelector(s => s.app.pageLoading);
    const initialLoaded = useSelector(s => s.app.initialLoaded);

    return (
        <>
            <Router>
                <MenusView path="/menus" />
                <SortView path="/sort" />
                <GroupsView path="/groups" />
                <AdminView default />
                <LoginView path="/login" />
            </Router>
            <div
                className={classNames(
                    "fixed top-0 left-0 w-full h-full pointer-events-none opacity-0 transition-opacity duration-75",
                    {
                        "opacity-100 pointer-events-all": status === "loading" || pageLoading,
                    },
                )}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
            ></div>
        </>
    );
};
