import { ChangeEvent, VFC } from "react";

interface Props {
    placeholder: string;
    value: string | undefined;
    onChange: (value: string) => any;
}

export const TextArea: VFC<Props> = ({ placeholder, value, onChange }) => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.currentTarget.value);
    };

    return (
        <textarea
            className="appearance-none bg-dark-lighter outline-none p-2 rounded-lg w-full"
            placeholder={placeholder}
            value={value || ""}
            onChange={handleChange}
            style={{ minHeight: "97px" }}
        />
    );
};
