import { RouteComponentProps } from "@reach/router";
import classNames from "classnames";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { AppBar } from "../appbar";
import { MenuModal } from "../modals/menu";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedField, setLocalizedFieldOnObject } from "../../lib/util";
import { Menu } from "../../interfaces/data";
import { showModal } from "../../store/modal";
import { Button } from "../button";
import { fetchData, moveMenuThunk, saveContentThunk } from "../../store/app";

export const MenusView: FC<RouteComponentProps> = ({ path }) => {
    const menus = useSelector(s => s.app.menus);
    const content = useSelector(s => s.app.content);
    const [localContent, setLocalContent] = useState(content || {});
    const status = useSelector(s => s.app.status);
    const lang = useSelector(s => s.app.lang);
    const transMode = useSelector(s => s.app.transMode);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchData());
    }, []);

    useEffect(() => {
        if (content) {
            setLocalContent(content);
        }
    }, [content]);

    const handleAddMenu = () => {
        dispatch(showModal(["menu", null]));
    };

    const handleContentChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setLocalContent(
            setLocalizedFieldOnObject(localContent, { footnote: evt.currentTarget.value }, lang, transMode),
        );
    };

    const handleSaveContent = () => {
        dispatch(saveContentThunk(localContent));
    };

    const handleOptionClick = (menu: Menu, key?: string) => {
        switch (key) {
            case "moveUp":
                dispatch(moveMenuThunk({ menuId: menu.id, direction: "up" }));
                break;

            case "moveDown":
                dispatch(moveMenuThunk({ menuId: menu.id, direction: "down" }));
                break;

            case "edit":
                dispatch(showModal(["menu", menu]));
                break;

            //             case "delete":
            //                 const confirmed = window.confirm("Variante löschen?");
            //                 if (!confirmed) return;
            //
            //                 dispatch(deleteCategoryThunk(cat.id));
            //                 break;
        }
    };

    const getSubmenu = () => {
        const submenu = [] as { key: string; label: string }[];

        if (transMode) {
            submenu.push({ key: "edit", label: "Bearbeiten" });
            return submenu;
        }

        if (menus.length > 1) {
            submenu.push({ key: "moveUp", label: "Nach oben" });
            submenu.push({ key: "moveDown", label: "Nach unten" });
        }

        submenu.push({ key: "edit", label: "Bearbeiten" });
        // submenu.push({ key: "delete", label: "Löschen" });

        return submenu;
    };

    return (
        <div>
            <AppBar path={path} />
            <main className="p-4 pt-20">
                {menus.map(menu => {
                    const localizedTitle = getLocalizedField(menu, "title", lang, transMode);
                    return (
                        <div className="mt-8" key={menu.id}>
                            <div className="flex">
                                <div
                                    className={classNames("text-3xl font-bold mb-4", {
                                        "text-gray-500": !localizedTitle,
                                    })}
                                >
                                    {localizedTitle || menu.title}
                                </div>
                                <div className="ml-auto h-full">
                                    <Button
                                        onClick={key => handleOptionClick(menu, key)}
                                        label=""
                                        slim
                                        submenu={getSubmenu()}
                                        submenuRight
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="currentColor"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
                {!transMode && <Button className="mt-4 inline-block" label="+ Karte" onClick={handleAddMenu} />}
                <div className="mt-8 text-3xl font-bold mb-4 border-t pt-6">Fußzeile</div>
                <div className="flex">
                    <textarea
                        className="appearance-none bg-dark-lighter w-full p-2 outline-none rounded-lg flex-grow-1"
                        value={getLocalizedField(localContent, "footnote", lang, transMode) || ""}
                        onChange={handleContentChange}
                        rows={10}
                    />
                    <div className="ml-4">
                        <div className="font-bold mb-2 text-lg">Formatierungsmöglichkeiten</div>
                        <div>
                            <strong>Überschrift:</strong>&nbsp;Zeile muss mit ### beginnen.
                        </div>
                        <div>
                            <strong>Fett:</strong>&nbsp;Textstelle muss mit ** beginnen und enden.
                        </div>
                        <div>
                            <strong>Link:</strong>&nbsp;Textstelle muss in eckiger Klammer geschrieben sein und der Link
                            dann danach in runder Klammer.
                        </div>
                        <div className="mt-2 font-bold">Beispiel:</div>
                        <code className="mt-2 block bg-dark-lighter p-2 rounded-lg">
                            ### Überschrift
                            <br />
                            <br />
                            Dieser Text beinhaltet **fettgedruckte Textstellen** und einen [Link](https://www.garger.at)
                        </code>
                    </div>
                </div>
                {<Button className="mt-4 inline-block" label="Speichern" onClick={handleSaveContent} />}
            </main>
            <MenuModal />
        </div>
    );
};
