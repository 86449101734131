export const setLocalizedFieldOnObject = (obj: any, add: object, lang: string, transMode: boolean) => {
    if (transMode) {
        let translations = Object.assign([], obj.translations) || [];
        if (!translations.find((t: any) => t.languages_code === lang)) {
            translations.push({
                languages_code: lang,
                ...add,
            });
        } else {
            translations = obj.translations.map((t: any) => {
                const newT = Object.assign({}, t)

                if (newT.languages_code === lang) {
                    for (const key of Object.keys(add)) {
                        newT[key] = (add as any)[key];
                    }
                }

                return newT;
            });
        }

        return {
            ...obj,
            translations,
        };
    }

    return {
        ...obj,
        ...add,
    };
};

export const getLocalizedField = (obj: any, key: string, lang: string, transMode: boolean) => {
    const defaultField = (obj as any)[key];

    if (transMode) {
        if (!obj.translations) {
            return "";
        }

        for (const trans of obj.translations) {
            if (trans.languages_code === lang) {
                const transField = (trans as any)[key];
                if (transField) {
                    return transField;
                }
            }
        }

        return "";
    }

    return defaultField;
};

export const trimString = (str: string, len: number): string => {
    if (str.length <= len) {
        return str;
    }

    return str.substr(0, len) + "...";
}
