import { stripHtml } from "string-strip-html";
import {
    Category,
    CategoryGroup,
    Content,
    Menu,
    Product,
    ProductCategoryRelation,
    ProductVariant,
} from "../interfaces/data";
import { cleanCategory, cleanMenu, cleanProduct } from "./util";

//
// Base
//
interface Response<P> {
    data: P;
}

export const buildUrl = (path: string): string => {
    return process.env.REACT_APP_GATEWAY_URL + path;
};

async function request_int(method: "DELETE", path: string, payload?: object): Promise<[number, null]>;
async function request_int<P>(method: string, path: string, payload?: object, noJson?: boolean): Promise<[number, P]>;

async function request_int<P>(
    method: string | "DELETE",
    path: string,
    payload?: object,
    noJson = false,
): Promise<[number, P | null]> {
    const baseUrl = buildUrl(path);
    const at = localStorage.getItem("at");

    let body = null;
    if (payload) {
        body = JSON.stringify(payload);
    }

    const res = await fetch(baseUrl, {
        method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${at}`,
        },
        body,
    });

    if (res.status === 401) {
        const rt = localStorage.getItem("rt");

        if (rt) {
            const r = await fetch("https://accounts.garger.services/api/v1/refresh", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${at}`,
                },
                body: JSON.stringify({ refreshToken: rt }),
            });

            if (r.status !== 200) {
                window.location.href = "/login";
            } else {
                const { accessToken, refreshToken } = (await r.json()) as { accessToken: string; refreshToken: string };

                localStorage.setItem("at", accessToken);
                localStorage.setItem("rt", refreshToken);

                return request_int(method, path, payload, noJson);
            }
        } else {
            window.location.href = "/login";
        }
    }

    if (method === "DELETE" || noJson) {
        return [res.status, null];
    }

    const resBody = (await res.json()) as Response<P>;

    return [res.status, resBody.data];
}

async function request(method: "DELETE", path: string, payload?: object): Promise<[number, null]>;
async function request<P>(method: string, path: string, payload?: object, noJson?: boolean): Promise<[number, P]>;

async function request<P>(
    method: string | "DELETE",
    path: string,
    payload?: object,
    noJson = false,
): Promise<[number, P | null]> {
    let status: number = 0;
    let body: P | null = null;

    for (let i = 3; i > 0; i--) {
        [status, body] = await request_int<P>(method, path, payload, noJson);

        if (status === 401) {
            const rt = localStorage.getItem("rt");
            if (rt) {
            }
            window.location.href = "/login";
        }

        if (status < 500) {
            return [status, body];
        }
    }

    return [status, body];
}

export const API = {
    //
    // Auth
    //
    async login(): Promise<string> {
        const r = await fetch("https://accounts.garger.services/api/v1/login-request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        if (r.status !== 200) {
            alert("Konnte Anmeldungssitzung nicht erstellen!");
            return "";
        }

        const { clientId } = (await r.json()) as { clientId: string };

        return clientId;
    },
    async checkLogin(clientId: string): Promise<boolean> {
        const r = await fetch("https://accounts.garger.services/api/v1/get-tokens", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ clientId }),
        });

        if (r.status !== 200) {
            return false;
        }

        const { accessToken, refreshToken } = (await r.json()) as { accessToken: string; refreshToken: string };

        try {
            const rawClaim = accessToken.split(".")[1];
            const claim = JSON.parse(atob(rawClaim));

            if (claim.acc.customer.menu_active) {
                localStorage.setItem("at", accessToken);
                localStorage.setItem("rt", refreshToken);

                return true;
            }

            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    },
    //
    // Menu
    //
    async fetchMenus(): Promise<Menu[]> {
        const [, body] = await request<Menu[]>("GET", "/menu?limit=-1&fields=*.*");

        return body.map(m => cleanMenu(m));
    },

    async createMenu(data: Omit<Menu, "id">): Promise<Menu> {
        const [, body] = await request<Menu>("POST", "/menu?fields=*.*", data);

        return body;
    },

    async patchMenu(data: Partial<Menu>): Promise<Menu | null> {
        if (!data.id) {
            return null;
        }

        const [, body] = await request<Menu>("PATCH", `/menu/${data.id}?fields=*.*`, data);

        return cleanMenu(body);
    },

    async updateMenuSort(from: string, to: string) {
        if (!from || !to) {
            return null;
        }

        const [status] = await request(
            "PATCH",
            `/menu/${from}/sort`,
            {
                to,
            },
            true,
        );

        return status;
    },

    //
    // Category
    //
    async fetchCategories(): Promise<Category[]> {
        const [, body] = await request<Category[]>("GET", "/category?limit=-1&fields=*.*");

        return body.map(c => cleanCategory(c));
    },

    async createCategory(data: Omit<Category, "id">): Promise<Category> {
        const [, body] = await request<Category>("POST", "/category?fields=*.*", data);

        return body;
    },

    async patchCategory(data: Partial<Category>): Promise<Category | null> {
        if (!data.id) {
            return null;
        }

        const [, body] = await request<Category>("PATCH", `/category/${data.id}?fields=*.*`, data);

        return cleanCategory(body);
    },

    async deleteCategory(id: string) {
        const [status] = await request("DELETE", `/category/${id}`);

        return status;
    },

    async updateCategorySort(from: string, to: string) {
        if (!from || !to) {
            return null;
        }

        const [status] = await request(
            "PATCH",
            `/category/${from}/sort`,
            {
                to,
            },
            true,
        );

        return status;
    },

    //
    // Content
    //
    async fetchContent(): Promise<Content> {
        const [, body] = await request<Content[]>("GET", "/content?limit=1&fields=*.*");

        return body[0];
    },

    async upsertContent(data: Partial<Content>) {
        const content = await API.fetchContent();
        if (!content) {
            const [, body] = await request<Content>("POST", "/content?fields=*.*", data);
            return body;
        }

        const [, body] = await request<Content>("PATCH", `/content/${data.id}?fields=*.*`, data);
        return body;
    },
    //
    // Category Groups
    //
    async fetchCategoryGroups(): Promise<CategoryGroup[]> {
        const [, body] = await request<CategoryGroup[]>("GET", "/category_group?limit=-1&fields=*.*");

        return body;
    },

    async createCategoryGroup(data: Omit<CategoryGroup, "id">): Promise<CategoryGroup> {
        const [, body] = await request<CategoryGroup>("POST", "/category_group?fields=*.*", data);

        return body;
    },

    async patchCategoryGroup(data: Partial<CategoryGroup>): Promise<CategoryGroup | null> {
        if (!data.id) {
            return null;
        }

        const [, body] = await request<CategoryGroup>("PATCH", `/category_group/${data.id}?fields=*.*`, data);

        return body;
    },

    async deleteCategoryGroup(id: string) {
        const [status] = await request("DELETE", `/category_group/${id}`);

        return status;
    },

    async updateCategoryGroupSort(from: string, to: string) {
        if (!from || !to) {
            return null;
        }

        const [status] = await request(
            "PATCH",
            `/category_group/${from}/sort`,
            {
                to,
            },
            true,
        );

        return status;
    },

    //
    // Product
    //
    async fetchProducts(): Promise<Product[]> {
        const [, body] = await request<Product[]>("GET", "/product?limit=-1&fields=*.*&fields=variants.*.*");

        return body.map(p => cleanProduct(p));
    },

    async createProduct(data: Omit<Product, "id">): Promise<Product> {
        const [, body] = await request<Product>("POST", "/product?fields=*.*&fields=variants.*.*", data);

        return body;
    },

    async patchProduct(data: Partial<Product>): Promise<Product | null> {
        if (!data.id) {
            return null;
        }

        const [, body] = await request<Product>("PATCH", `/product/${data.id}?fields=*.*&fields=variants.*.*`, data);

        return cleanProduct(body);
    },

    async patchProductVariant(data: Partial<ProductVariant>): Promise<ProductVariant | null> {
        if (!data.id) {
            return null;
        }

        const [, body] = await request<ProductVariant>("PATCH", `/product/${data.id}?fields=*.*`, data);

        return body;
    },

    async deleteProduct(id: string) {
        const [status] = await request("DELETE", `/product/${id}`);

        return status;
    },

    async updateProductSort(products: Product[]) {
        for (const prod of products) {
            await this.patchProduct({
                id: prod.id,
                categories: prod.categories.map<any>(c => ({
                    id: c.id,
                    sort: c.sort,
                })),
            });
        }
    },

    async updateProductVariantSort(productId: string, variants: ProductVariant[]) {
        return await this.patchProduct({
            id: productId,
            variants: variants.map(v => ({ id: v.id, sort: v.sort || 0 })) as any,
        });
    },
};
