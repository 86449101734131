import { useNavigate } from "@reach/router";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLang, setPageLoading } from "../store/app";
import { Logo } from "./logo";

const langLUT = {
    default: "Deutsch",
    "en-US": "Englisch",
} as { [key: string]: string };

const views = {
    "/": "Produktverwaltung",
    "/menus": "Kartenverwaltung",
    "/groups": "Kategorie-Gruppen",
    "/sort": "Kategoriesortierung",
} as { [key: string]: string };

interface Props {
    path?: string;
}

export const AppBar: FunctionComponent<Props> = ({ path }) => {
    const [username, setUsername] = useState();
    const lang = useSelector(s => s.app.lang);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLangChange = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setLang(event.currentTarget.value as any));
    };

    const handleViewChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const path = event.currentTarget.value;
        dispatch(setPageLoading(true));
        await new Promise(r => setTimeout(r, 500));
        await navigate(path);
        dispatch(setPageLoading(false));
    };

    const handleLogout = () => {
        window.localStorage.removeItem("at");
        window.localStorage.removeItem("rt");

        window.location.href = "/login";
    }

    useEffect((): void => {
        const at = window.localStorage.getItem("at");
        if (!at) return;
        const rawContent = at.split(".")[1];
        if (!rawContent) return;
        const content = JSON.parse(atob(rawContent));

        setUsername(content["acc"]["username"]);
    }, []);

    return (
        <div className="flex bg-dark-light w-full fixed top-0 left-0 w-full z-10 shadow-lg">
            <div className="flex items-center justify-center">
                <div className="bg-primary p-2">
                    <Logo width="60" height="60" />
                </div>
                <div className="text-white px-6 bg-dark-lighter h-full flex justify-center flex-col">
                    <div className="font-bold text-xl">Digitale Speisekarte</div>
                    <div>garger.services</div>
                </div>
                <div className="border-r-2 border-dark-lighter h-full flex flex-col justify-center relative">
                    <div className="flex items-center px-4 ">
                        <div className="font-bold pr-2">{views[path || "/"]}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" fill="currentColor" />
                        </svg>
                        <select
                            className="absolute left-0 w-full opacity-0 top-0 h-full text-black"
                            value={path || "/"}
                            onChange={handleViewChange}
                        >
                            {Object.keys(views).map(v => (
                                <option key={v} value={v}>
                                    {views[v]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="border-r-2 border-dark-lighter h-full flex flex-col justify-center relative">
                    <div className="flex items-center px-4 ">
                        <div className="font-bold pr-2">{langLUT[lang]}</div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" fill="currentColor" />
                        </svg>
                        <select
                            className="absolute left-0 w-full opacity-0 top-0 h-full text-black"
                            value={lang}
                            onChange={handleLangChange}
                        >
                            {["default", "en-US"].map(l => (
                                <option key={l} value={l}>
                                    {langLUT[l]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="ml-auto h-auto flex items-center justify-center">
                <div className="pr-4 text-right">
                    <div className="font-bold">{username}</div>
                    <div onClick={handleLogout}>Abmelden</div>
                </div>
            </div>
        </div>
    );
};
